const initialState = {
    itemdetails: [],
    selectedItem: {},
    storedetails: [],
    selectedStore: {},
    exceptionDescription: '',
    exceptionReason: '',
    startDate: null,
    endDate: null,
    exception: {},
    editException: {},
    saveExceptionSuccess: null,
    exceptionError: null,
    itemFlag: true,
    storeFlag: true,
    exceptionReasonList: [],
    totalExceptionCount: 100000,
};

export default initialState;
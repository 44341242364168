import {
  IL_SUMMARY_SELECTED_ITEM_LIST_ID, IL_ITEM_LIST_DELETE, IL_SUMMARY_RESET,
  IL_SUMMARY_FILTER_SELECTED_ITEM_LIST_ID, IL_SUMMARY_SELECTED_ITEM_LIST_DESCRIPTION,
  IL_SUMMARY_SEARCHED_DATA, ITEMS_IN_ITEM_LIST, IL_SEARCH_ERROR, IL_BANNER_CLOSE, IL_FILTER_CREATED_BY, IL_FILTER_SAIN_ID, IL_FILTER_LEGACY_ITEM_ID
} from '../../../../Common/Constants/types';
import initialState from './initialState';

const Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case IL_SUMMARY_RESET:
      return initialState;
    case IL_SUMMARY_SELECTED_ITEM_LIST_ID: {
      return { ...state, selectedItemListIds: payload };
    }
    case IL_SUMMARY_FILTER_SELECTED_ITEM_LIST_ID: {
      return { ...state, selectedItemListID: payload };
    }
    case IL_SUMMARY_SELECTED_ITEM_LIST_DESCRIPTION: {
      return { ...state, selectedItemListDescription: payload };
    }
    case IL_FILTER_CREATED_BY: {
      return { ...state, selectedItemListCreatedBy: payload };
    }
    case IL_FILTER_SAIN_ID: {
      return { ...state, selectedItemListSainId: payload };
    }
    case IL_FILTER_LEGACY_ITEM_ID: {
      return { ...state, selectedItemListLegacyItemId: payload };
    }
    case IL_SUMMARY_SEARCHED_DATA: {
      const error = payload.searchResultItemList?.length > 0 ? null : 'No records found';
      return { ...state, itemList: payload, itemListSearchError: error }
    }
    case IL_ITEM_LIST_DELETE: {
      return { ...state, deleteSuccess: payload, selectedItemListIds: [] };
    }
    case ITEMS_IN_ITEM_LIST: {
      return { ...state, itemsInList: payload }
    };
    case IL_SEARCH_ERROR: {
      return { ...state, itemListSearchError: payload };
    }
    case IL_BANNER_CLOSE: {
      return { ...state, openBanner: payload };
    }
    default:
      return state;
  }
};

export default Reducer;
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress';
import { memo } from 'react';

const Loader = () => (
  <ProgressIndicator page loading>
    <ProgressSpinner />
    Loading...
  </ProgressIndicator>
);

export default memo(Loader);
import { combineReducers } from 'redux';
import Reducer from '../Reducer';
import CreateExceptionReducer from '../../../Container/Exception/Create/Reducer';
import CreateStoreListReducer from '../../../Container/StoreList/Create/Reducer';
import CreateItemListReducer from '../../../Container/ItemList/Create/Reducer';
import SearchExceptionReducer from '../../../Container/Exception/Summary/Reducer';
import SearchStoreListReducer from '../../../Container/StoreList/Summary/Reducer';
import SearchItemListReducer from '../../../Container/ItemList/Summary/Reducer';
import ItemStoreInListReducer from '../../../Container/ListItemStore/Reducer';

const RootReducer = combineReducers({
    Reducer,
    CreateExceptionReducer,
    CreateStoreListReducer,
    CreateItemListReducer,
    SearchExceptionReducer,
    SearchStoreListReducer,
    SearchItemListReducer,
    ItemStoreInListReducer,
});

export default RootReducer;

import {
    CLEAR_STORE_LIST_PREVIEW,
    DELETE_STORE_FROM_PREVIEW,
    MULTIPLE_STORE_IDS_SEARCH,
    RESET_STORE_LIST_DETAILS, SAVE_STORE_LIST,
    SAVE_STORE_TO_PREVIEW, SELECTED_STORE_IN_SEARCH,
    SELECT_STORE_IN_STORE_LIST,
    SET_SL_EDIT_DATA,
    SET_STORE_LIST_DESCRIPTION,
    SL_ERROR,
    SL_RESET,
    STORE_TYPE_LIST,
    UNSELECT_STORE_IN_STORE_LIST
} from '../../../../Common/Constants/types';
import initialState from './initialState';

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_STORE_LIST_DESCRIPTION: {
            return { ...state, selectedStoreListDescription: payload }
        }
        case SELECT_STORE_IN_STORE_LIST: {
            const error = payload?.length > 0 ? null : 'No records found';
            return { ...state, storedetails: payload, storeListError: error }
        }
        case MULTIPLE_STORE_IDS_SEARCH: {
            return {
                ...state,
                storedetails: payload.storeDataList,
                invalidStores: payload.invalidStores,
                closedStores: payload.closedStores,
            }
        }
        case UNSELECT_STORE_IN_STORE_LIST: {
            return { ...state, storedetails: [], closedStores: [], invalidStores: [] }
        }
        case SELECTED_STORE_IN_SEARCH: {
            return { ...state, selectedStoresInSearch: payload }
        }
        case SAVE_STORE_TO_PREVIEW: {
            const { selectedStoreList, storedetails } = state;
            const placeIds = selectedStoreList?.map((p) => { return p.placeId });
            const updatedStoreDetails = storedetails.filter((i) => !payload?.includes(i));
            return {
                ...state,
                selectedStoreList:
                    selectedStoreList?.concat(payload?.filter((p) => !placeIds?.includes(p.placeId))),
                selectedStoresInSearch: [],
                storedetails: updatedStoreDetails,
            };
        }
        case SAVE_STORE_LIST: {
            return { ...state, saveStoreListSuccess: payload };
        }
        case RESET_STORE_LIST_DETAILS: {
            return {
                ...state, selectedStoreListDescription: '',
                selectedStoreList: [], storedetails: []
            };
        }
        case CLEAR_STORE_LIST_PREVIEW: {
            return {
                ...state, selectedStoreList: [],
            }
        }
        case DELETE_STORE_FROM_PREVIEW: {
            const { selectedStoreList } = state;
            const updatedDetails = selectedStoreList?.filter((d) => d !== payload);
            return { ...state, selectedStoreList: [...updatedDetails] };
        }
        case SET_SL_EDIT_DATA: {
            return { ...state, editStoreList: payload };
        }
        case STORE_TYPE_LIST: {
            return { ...state, storeTypeList: payload }
        }
        case SL_RESET: {
            return initialState;
        }
        case SL_ERROR: {
            return { ...state, storeListError: payload };
        }
        default:
            return state;
    }
};

export default reducer;
import {
    DELETE_ITEM_FROM_PREVIEW,
    RESET_ITEM_LIST_DETAILS, SAVE_ITEM_LIST,
    SAVE_ITEM_TO_PREVIEW, SELECT_ITEM_IN_ITEM_LIST,
    SET_ITEM_LIST_DESCRIPTION, UNSELECT_ITEM_IN_ITEM_LIST, SELECTED_ITEM_IN_SEARCH,
    SET_IL_EDIT_DATA, IL_RESET, IL_ERROR, MULTIPLE_ITEMS_IDS_SEARCH, CLEAR_ITEM_LIST_PREVIEW,
} from '../../../../Common/Constants/types';
import initialState from './initialState';

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_ITEM_LIST_DESCRIPTION: {
            return { ...state, selectedItemListDescription: payload }
        }
        case SELECT_ITEM_IN_ITEM_LIST: {
            const error = payload?.length > 0 ? null : 'No records found';
            return { ...state, itemdetails: payload, itemListError: error }
        }
        case MULTIPLE_ITEMS_IDS_SEARCH: {
            return {
                ...state,
                itemdetails: payload.itemDataList,
                invalidItems: payload.invalidItems,
                inactiveItems: payload.inactiveItems,
            }
        }
        case UNSELECT_ITEM_IN_ITEM_LIST: {
            return { ...state, itemdetails: [], invalidItems: [], inactiveItems: [] }
        }
        case SELECTED_ITEM_IN_SEARCH: {
            return { ...state, selectedItemsInSearch: payload }
        }
        case SAVE_ITEM_TO_PREVIEW: {
            const { selectedItemList, itemdetails } = state;
            const sainIds = selectedItemList?.map((p) => { return p.sainId });
            const updatedIemDetails = itemdetails.filter((i) => !payload?.includes(i));
            return {
                ...state,
                selectedItemList:
                    selectedItemList?.concat(payload?.filter((p) => !sainIds?.includes(p.sainId))),
                selectedItemsInSearch: [],
                itemdetails: updatedIemDetails,
            };
        }
        case SAVE_ITEM_LIST: {
            return { ...state, saveItemListSuccess: payload };
        }
        case RESET_ITEM_LIST_DETAILS: {
            return {
                ...state, selectedItemListDescription: '',
                selectedItemList: [], itemdetails: []
            };
        }
        case CLEAR_ITEM_LIST_PREVIEW: {
            return {
                ...state, selectedItemList: [],
            }
        }
        case DELETE_ITEM_FROM_PREVIEW: {
            const { selectedItemList } = state;
            const updatedDetails = selectedItemList?.filter((d) => d !== payload);
            return { ...state, selectedItemList: [...updatedDetails] };
        }
        case SET_IL_EDIT_DATA: {
            return { ...state, editItemList: payload };
        }
        case IL_RESET: {
            return initialState;
        }
        case IL_ERROR: {
            return { ...state, itemListError: payload };
        }
        default:
            return state;
    }
};

export default reducer;
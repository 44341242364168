import { memo, useEffect } from 'react';
import moment from 'moment';
import { Table } from '@jsluna/table';
import { Card } from '@jsluna/card';
import { OutlinedButton, } from '@jsluna/button';
import { GridWrapper, GridItem } from '@jsluna/grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getItemsInListId } from '../Reducer/Action';
import { SEARCH_ITEM_LIST } from '../../../Common/Constants/routePaths';

const ItemsInListTable = () => {

    const { state: { itemListId: id } } = useLocation();
    const dispatch = useDispatch();
    const navigation = useNavigate();

    useEffect(() => {
        dispatch(getItemsInListId(id));
    }, []);

    const itemsInList = useSelector((state) => state.ItemStoreInListReducer.itemsInList);

    return (
        <div className="ln-u-soft ln-u-padding*1" align='center'>
            <Card>
                {(itemsInList?.length > 0) && (
                    <GridWrapper>
                        <GridItem size={{ sm: '1/2' }}>
                            <strong>Item List Id : </strong>{itemsInList[0].itemListId}
                        </GridItem>
                        <GridItem size={{ sm: '1/2' }}>
                            <strong>Item List Description : </strong>{itemsInList[0].itemListDesc}
                        </GridItem>
                        <GridItem size={{ sm: '1/2' }}>
                            <strong>Created by : </strong>{itemsInList[0].userCreated}
                        </GridItem>
                        <GridItem size={{ sm: '1/2' }}>
                            <strong>Creation Date : </strong>{moment((itemsInList[0].createdDate).slice(0, 10)).format('DD-MM-YYYY')
                                + ' ' + (itemsInList[0].createdDate).slice(11, 19)}
                        </GridItem>
                    </GridWrapper>
                )}
            </Card>
            <br></br>
            <Card>
                <GridItem
                    size='1'
                    className="ln-u-border ln-u-soft"
                    style={{ maxHeight: '800px', overflowY: 'auto' }}
                >
                    <Table
                        rowKey="item"
                        data={itemsInList}
                        columns={[
                            {
                                name: "Sain Id",
                                accessor: (rowData) => ({ value: rowData.sainId }),
                            },
                            {
                                name: "Legacy Item Id",
                                accessor: (rowData) => ({ value: rowData.legacyItemId }),
                            },
                        ]}
                    />
                </GridItem>
                <GridWrapper gutterSize='sm' >
                    <GridItem size='1/8' offsetRight={{ md: '5/12' }}>
                        <OutlinedButton
                            className="ln-u-margin-top*2 ln-u-margin-left*4"
                            color="dark"
                            fullWidth
                            onClick={() => navigation(SEARCH_ITEM_LIST)}
                        >
                            Back
                        </OutlinedButton>
                    </GridItem>
                </GridWrapper>
            </Card>
        </div>
    );
};

export default memo(ItemsInListTable); 

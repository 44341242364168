import { call, put, takeEvery } from '@redux-saga/core/effects';
import { API_REQUEST } from '../../Constants/types';
import { handleSetError, handleSetLoading } from '../Reducer/Action';
import API from '../API';

let pendingRequest = 0;

export function* handleRequest(action) {
  const { payload } = action;
  const {
    method, url, body, handleSuccess,
  } = payload;
  pendingRequest += 1;
  /* istanbul ignore next */
  if (pendingRequest === 1) yield put(handleSetLoading(true));
  yield put(handleSetError(null));
  try {
    const data = yield call(API, method, url, body);
    yield put(handleSuccess(data));
  } catch (error) {
    const errorObject = error.response
      ? {
        method,
        url,
        status: error.response.status,
        error: error.response.error,
        message: error.response.data,
      }
      : { method, url, message: error.message };
    yield put(handleSetError(errorObject));
  }
  pendingRequest -= 1;
  /* istanbul ignore next */
  if (pendingRequest === 0) yield put(handleSetLoading(false));
}

function* RootSaga() {
  yield takeEvery(API_REQUEST, handleRequest);
}

export default RootSaga;

import { memo } from "react";
import { Display1, Display2 } from "@jsluna/typography";
import { Link } from "@jsluna/link";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Card } from "@jsluna/card";

const NoAccessMessage = () => {
  return (
    <>
      <Card
        style={{ marginTop: "4rem", marginLeft: "17rem", marginRight: "17rem" }}
      >
        <GridWrapper>
          <GridItem className="ln-u-text-align-center">
            <Display2>
              Sorry! It seems you don't have access to Non Replenishment Lines.
            </Display2>
            <br />
            <br />
            <Display1>
              Go Back to
              <Link
                style={{ color: "#f06c00" }}
                href={process.env.REACT_APP_REDIRECT_URI}
              >
                {" "}
                SCMDM{" "}
              </Link>
              Home Page.
            </Display1>
          </GridItem>
        </GridWrapper>
      </Card>
    </>
  );
};

export default memo(NoAccessMessage);

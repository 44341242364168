/* istanbul ignore file */
import { memo, useState, useEffect, lazy } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  ROOT_PATH,
  EXCEPTION,
  STORE_LIST_PATH,
  ITEM_LIST_PATH,
  ADD_EXCEPTION,
  STORE_LIST_CREATE_PATH,
  ITEM_LIST_CREATE_PATH,
  SEARCH_EXCEPTION,
  SEARCH_STORE_LIST,
  SEARCH_ITEM_LIST,
  EDIT_EXCEPTION,
  STORE_LIST_EDIT_PATH,
  ITEM_LIST_EDIT_PATH,
  WZO_ROOT_PATH,
  STORES_IN_STORE_LIST,
  ITEMS_IN_ITEM_LIST,
} from "../Common/Constants/routePaths";
import NavBar from "./Components/NavBar";

import { loginRequest } from "../authConfig";
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import {
  InteractionType,
  InteractionRequiredAuthError,
  BrowserAuthError,
} from "@azure/msal-browser";
import Axios from "axios";
import jwtDecode from "jwt-decode";
import HomePage from "./Components/HomePage";
import StoresInList from "../Container/ListItemStore/StoreInList";
import ItemsInList from "../Container/ListItemStore/ItemInList";
import { useSelector } from "react-redux";
import Loader from "../Common/Components/Loader";
import NoAccess from "./Components/NoAccess";

const LandingPage = lazy(() => import("../Container/LandingPage"));
const Exception = lazy(() => import("../Container/Exception"));
const StoreList = lazy(() => import("../Container/StoreList"));
const ItemList = lazy(() => import("../Container/ItemList"));
const CreateException = lazy(() => import("../Container/Exception/Create"));
const CreateStoreList = lazy(() => import("../Container/StoreList/Create"));
const CreateItemList = lazy(() => import("../Container/ItemList/Create"));
const SearchException = lazy(() => import("../Container/Exception/Summary"));
const SearchStoreList = lazy(() => import("../Container/StoreList/Summary"));
const SearchItemList = lazy(() => import("../Container/ItemList/Summary"));

const RoutesConst = () => {
  const navigation = useNavigate();

  const [role, setRole] = useState([]);
  const [username, setUsername] = useState("");
  const [bcID, setBCId] = useState("");
  const { instance, accounts } = useMsal();
  const [acc, setAcc] = useState(accounts[0]);

  const { pathname } = useLocation();

  const isAuthenticated = useIsAuthenticated();

  const isLoading = useSelector((state) => state.Reducer.loading);
  const e2eToken = localStorage.getItem("e2eToken");

  const request = {
    ...loginRequest,
    account: accounts[0] === undefined ? acc : accounts[0],
    forceRefresh: true,
  };
  // account: accounts[0] === undefined ? JSON.parse(localStorage.getItem('accounts')) : accounts[0],
  const handleAccountDetails = (response) => {
    const { name, preferred_username, roles } = jwtDecode(response.idToken);
    setRole(roles);
    setUsername(name.split(" ")[0]);
    setBCId(preferred_username);
    Axios.defaults.headers.common.Authorization = `Bearer ${response.idToken}`;
    localStorage.setItem("idToken", response.idToken);
  };

  const handleTokenRefresh = () => {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        handleAccountDetails(response);
      })
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request).then((response) => {
            InteractionRequiredAuthError(response.idToken);
          });
        }
        if (
          e instanceof BrowserAuthError &&
          (e.errorCode === "popup_window_error" ||
            e.errorCode === "empty_window_error")
        ) {
          instance.acquireTokenRedirect(request).then((response) => {
            handleAccountDetails(response.idToken);
          });
        }
      })
      .catch(() => {
        localStorage.removeItem("idToken");
      });
  };

  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  );
  useEffect(() => {
    if (e2eToken !== "true") {
      if (error && error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect, loginRequest).catch((err) => {
          if (
            err instanceof BrowserAuthError &&
            (err.errorCode === "popup_window_error" ||
              err.errorCode === "empty_window_error")
          ) {
            login(InteractionType.Redirect, loginRequest);
          }
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (e2eToken !== "true") {
      const id = setInterval(handleTokenRefresh, 3000000);
      return () => clearInterval(id);
    }
    if (e2eToken === "true") {
      const roles = localStorage.getItem("roles");
      setRole(roles);
      setUsername("Test User");

      const authToken = localStorage.getItem("idtoken");
      Axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    }
  }, []);

  useEffect(() => {
    if (e2eToken !== "true") {
      if (accounts.length > 0) {
        handleTokenRefresh();
        setAcc(accounts[0]);
        // localStorage.setItem('accounts', JSON.stringify(accounts[0]));
      }
    }
  }, [accounts]);

  return (
    (isAuthenticated || e2eToken === "true") &&
    role?.length > 0 && (
      <div>
        {pathname === ROOT_PATH ? (
          <HomePage navigation={navigation} name={username}></HomePage>
        ) : (
          <>
            {role?.includes("mdm-wzo") ||
            role?.includes("mdm-wzo_read-only") ? (
              <>
                <NavBar
                  navigation={navigation}
                  instance={instance}
                  name={username}
                  timeOut={900}
                  awaitTime={10000}
                />
                {isLoading && <Loader />}
                <Routes>
                  <Route
                    path={ROOT_PATH}
                    element={<HomePage navigation={navigation} />}
                  />
                  <Route
                    path={WZO_ROOT_PATH}
                    element={<LandingPage navigation={navigation} />}
                  />
                  <Route
                    path={EXCEPTION}
                    element={<Exception navigation={navigation} roles={role} />}
                  />
                  <Route
                    path={ADD_EXCEPTION}
                    element={
                      <CreateException navigation={navigation} user={bcID} />
                    }
                  />
                  <Route
                    path={EDIT_EXCEPTION}
                    element={
                      <CreateException navigation={navigation} user={bcID} />
                    }
                  />
                  <Route
                    path={SEARCH_EXCEPTION}
                    element={
                      <SearchException
                        navigation={navigation}
                        roles={role}
                        user={bcID}
                      />
                    }
                  />
                  <Route
                    path={STORE_LIST_PATH}
                    element={<StoreList navigation={navigation} roles={role} />}
                  />
                  <Route
                    path={STORE_LIST_CREATE_PATH}
                    element={
                      <CreateStoreList navigation={navigation} user={bcID} />
                    }
                  />
                  <Route
                    path={STORE_LIST_EDIT_PATH}
                    element={
                      <CreateStoreList navigation={navigation} user={bcID} />
                    }
                  />
                  <Route
                    path={SEARCH_STORE_LIST}
                    element={
                      <SearchStoreList
                        navigation={navigation}
                        roles={role}
                        user={bcID}
                      />
                    }
                  />
                  <Route
                    path={ITEM_LIST_PATH}
                    element={<ItemList navigation={navigation} roles={role} />}
                  />
                  <Route
                    path={ITEM_LIST_CREATE_PATH}
                    element={
                      <CreateItemList navigation={navigation} user={bcID} />
                    }
                  />
                  <Route
                    path={ITEM_LIST_EDIT_PATH}
                    element={
                      <CreateItemList navigation={navigation} user={bcID} />
                    }
                  />
                  <Route
                    path={SEARCH_ITEM_LIST}
                    element={
                      <SearchItemList
                        navigation={navigation}
                        roles={role}
                        user={bcID}
                      />
                    }
                  />
                  <Route
                    path={STORES_IN_STORE_LIST}
                    element={<StoresInList />}
                  />
                  <Route path={ITEMS_IN_ITEM_LIST} element={<ItemsInList />} />
                </Routes>
              </>
            ) : (
              <>
                <NoAccess />
              </>
            )}
          </>
        )}
      </div>
    )
  );
};

export default memo(RoutesConst);

/* istanbul ignore file */
import { Modal } from '@jsluna/modal';
import { Display1, Body1 } from '@jsluna/typography';
import PropTypes from 'prop-types';
import { memo, useState, useEffect } from 'react';
import { FilledButton } from '@jsluna/button';

const InactiveAlert = memo((props) => {
  const {
    open, awaitTime, closeSessionPopup,
  } = props;

  const [seconds, setSeconds] = useState(awaitTime / 1000);

  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds('0:00');
    }

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    setSeconds(awaitTime / 1000);
  }, [open]);

  return (
    <Modal
      small
      restrictClose
      open={open}
      headingId="info-modal"
    >
      <Display1 element="p" className="ln-u-text-align-center">Session Inactivity</Display1>
      <Body1 element="p" className="ln-u-font-weight-bold ln-u-margin-bottom*2 ln-u-text-align-center">
        You will be logged off in
        {' '}
        <strong>{seconds}</strong>
        {' '}
        seconds due to inactivity.
        {' '}
        <br />
        <br />
        <FilledButton onClick={() => closeSessionPopup()}>Click here to stay logged in.</FilledButton>
      </Body1>
    </Modal>
  );
});

InactiveAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  awaitTime: PropTypes.number.isRequired,
  closeSession: PropTypes.func.isRequired,
};

export default InactiveAlert;
import { memo } from "react";
import { StatusCard } from "@jsluna/card";
import { Heading3 } from "@jsluna/typography";
import { WZO_ROOT_PATH } from "../../../Common/Constants/routePaths";
import { useNavigate } from "react-router-dom";
import NavBar from "../NavBar";
import { useMsal } from "@azure/msal-react";

const HomePage = (props) => {
  const navigation = useNavigate();
  const { instance } = useMsal();
  const { name } = props;

  return (
    <>
      <NavBar navigation={navigation} instance={instance} name={name} />
      <div className="ln-u-soft">
        <Heading3 className="ln-u-text-align-left ln-u-soft">
          Supply Chain Master Data Management{" "}
        </Heading3>
        <StatusCard
          className="ln-u-text-align-left ln-u-soft"
          style={{ width: "100%" }}
          onClick={() => navigation(WZO_ROOT_PATH)}
          status="danger"
        >
          <h4>Non-Replenishment Lines</h4>
          <span>
            Create or view exceptions giving instructions not to send selected
            products to selected stores for a defined period of time
          </span>
        </StatusCard>
        <br />
        <StatusCard
          className="ln-u-text-align-left ln-u-soft"
          style={{ width: "100%" }}
          status="danger"
          onClick={() =>
          (window.location.href =
            process.env.REACT_APP_STK_STKLS_REDIRECT_URI)
          }
        >
          <h4>Stocked & Stockless Indicators</h4>
          <span>
            Create or view changes to the stocking method for selected
            product/pack sizes at selected depots
          </span>
        </StatusCard>
        <br />
        <StatusCard
          className="ln-u-text-align-left ln-u-soft"
          style={{ width: "100%" }}
          status="danger"
          onClick={() =>
          (window.location.href =
            process.env.REACT_APP_LOCAL_EVENTS_REDIRECT_URI)
          }
        >
          <h4>Local Events</h4>
          <span>
            Create, view or edit new or existing local events to ensure accurate
            forecasting and store ordering
          </span>
        </StatusCard>
        <br />
        <StatusCard
          className="ln-u-text-align-left ln-u-soft"
          style={{ width: "100%" }}
          status="danger"
          onClick={() =>
          (window.location.href =
            process.env.REACT_APP_STORE_SALES_ORDER_DATE_REDIRECT_URI)
          }
        >
          <h4>Store Sales & Order Dates</h4>
          <span>
            Create or view exceptions to default dates for selected product new
            to selected stores
          </span>
        </StatusCard>
        <br />
        <StatusCard
          className="ln-u-text-align-left ln-u-soft"
          style={{ width: "100%" }}
          status="danger"
          onClick={() =>
            (window.location.href = process.env.REACT_APP_FINAL_DEPOT_SUPPLIER_ORDER_DATE_REDIRECT_URI)
          }
        >
          <h4>Final Depot Supplier Order Date</h4>
          <span>
            Add, view or amend a supplier final depot order date
            and final order quantity in line with the commercial agreement and delisting process
          </span>
        </StatusCard>
        <br />
        <StatusCard
          className="ln-u-text-align-left ln-u-soft"
          style={{ width: "100%" }}
          status="danger"
          onClick={() =>
            (window.location.href = process.env.REACT_APP_DEMAND_GROUPS_REDIRECT_URI)
          }
        >
          <h4>Demand Groups</h4>
          <span>
            Create or view Demand Groups
          </span>
        </StatusCard>
        <br />
        <StatusCard
          className="ln-u-text-align-left ln-u-soft"
          style={{ width: "100%" }}
          status="danger"
          onClick={() =>
            (window.location.href = process.env.REACT_APP_ITEM_STORE_DISTRIBUTION_REDIRECT_URI)
          }
        >
          <h4>Item Store Distribution</h4>
          <span>
            Create or view Item Store Distribution
          </span>
        </StatusCard>
      </div>
    </>
  );
};

export default memo(HomePage);

import { SET_LOADING, SET_ERROR } from '../../../Constants/types';

export const handleSetLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const handleSetError = (payload) => ({
  type: SET_ERROR,
  payload,
});

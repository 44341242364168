const initialState = {
    itemList: {
        searchResultItemList: [],
        resultSize: 1,
    },
    selectedItemListIds: [],
    selectedItemListID: '',
    selectedItemListDescription: '',
    selectedItemListCreatedBy: '',
    selectedItemListSainId: '',
    selectedItemListLegacyItemId: '',
    deleteSuccess: false,
    itemsInList: [],
    itemListSearchError: null,
    openBanner: true,
};

export default initialState;


const initialState = {
    itemdetails: [],
    invalidItems: [],
    inactiveItems: [],
    selectedItemList: [],
    selectedItemsInSearch: [],
    selectedItemListDescription: '',
    saveItemListSuccess: null,
    editItemList: [],
    itemListError: null,
};

export default initialState;
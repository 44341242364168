import {
    SELECT_ITEM, UNSELECT_ITEM, SELECT_STORE, UNSELECT_STORE,
    SET_EXCEPTION_DESCRIPTION, SET_EXCEPTION_REASON, SET_EXCEPTION_START_DATE,
    SET_EXCEPTION_END_DATE, RESET_EXCEPTION_DETAILS, SAVE_EXCEPTION,
    SET_EXCEPTION_EDIT_DATA, EXCEPTION_RESET, EXCEPTION_ERROR, EXCEPTION_STORE_FLAG,
    EXCEPTION_ITEM_FLAG, EXCEPTION_REASONS, SELECT_STORE_BRAND, SELECT_ITEM_BRAND,
} from '../../../../Common/Constants/types';
import initialState from './initialState';

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_EXCEPTION_DESCRIPTION: {
            return { ...state, exceptionDescription: payload }
        }
        case SET_EXCEPTION_REASON: {
            return { ...state, exceptionReason: payload }
        }
        case SET_EXCEPTION_START_DATE: {
            return { ...state, startDate: payload }
        }
        case SET_EXCEPTION_END_DATE: {
            return { ...state, endDate: payload }
        }
        case SELECT_ITEM: {
            // const updatedpayload = payload?.length > 0 ? [payload[0]] : [];
            const error = payload?.length > 0 ? null : 'No records found';
            return { ...state, itemdetails: payload, exceptionError: error }
        }
        case SELECT_ITEM_BRAND: {
            return { ...state, selectedItem: payload };
        }
        case UNSELECT_ITEM: {
            return { ...state, itemdetails: [] }
        }
        case SELECT_STORE: {
            // const updatedpayload = payload?.length > 0 ? [payload[0]] : [];
            const error = payload?.length > 0 ? null : 'No records found';
            return { ...state, storedetails: payload, exceptionError: error }
        }
        case SELECT_STORE_BRAND: {
            return { ...state, selectedStore: payload };
        }
        case UNSELECT_STORE: {
            return { ...state, storedetails: [] }
        }
        case SAVE_EXCEPTION: {
            return { ...state, saveExceptionSuccess: payload };
        }
        case SET_EXCEPTION_EDIT_DATA: {
            return { ...state, editException: payload }
        }
        case EXCEPTION_ITEM_FLAG: {
            return { ...state, itemFlag: payload };
        }
        case EXCEPTION_STORE_FLAG: {
            return { ...state, storeFlag: payload };
        }
        case RESET_EXCEPTION_DETAILS: {
            return {
                ...state, exceptionDescription: '', exceptionReason: '', startDate: null,
                endDate: null, itemFlag: true, storeFlag: true, selectedStore: {}, editException: {},
            }
        }
        case EXCEPTION_RESET: {
            return initialState;
        }
        case EXCEPTION_ERROR: {
            return { ...state, exceptionError: payload }
        }
        case EXCEPTION_REASONS: {
            return { ...state, exceptionReasonList: payload }
        }
        default:
            return state;
    }
};

export default reducer;
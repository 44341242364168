const initialState = {
  exception: {
    exceptionSearchResult: [],
    resultSize: 1,
  },
  selectedExceptionIds: [],
  deleteSuccess: false,
  storesInList: [],
  itemsInList: [],
  exceptionSearchError: null,
  openBanner: true,
  rowsOffset: 50,
  allStores: []
};

export default initialState;

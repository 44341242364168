import {
  SL_SUMMARY_SELECTED_STORE_LIST_ID, SL_STORE_LIST_DELETE, SL_SUMMARY_RESET,
  SL_SUMMARY_FILTER_SELECTED_STORE_LIST_ID, SL_SUMMARY_SELECTED_STORE_LIST_DESCRIPTION,
  SL_SUMMARY_SEARCHED_DATA, STORES_IN_STORE_LIST, SL_SEARCH_ERROR, SL_BANNER_CLOSE,
  SL_FILTER_CREATED_BY, SL_FILTER_PLACE_ID, SL_FILTER_LEGACY_STORE_ID
} from '../../../../Common/Constants/types';
import initialState from './initialState';

const Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SL_SUMMARY_RESET:
      return initialState;
    case SL_SUMMARY_SELECTED_STORE_LIST_ID: {
      return { ...state, selectedStoreListIds: payload };
    }
    case SL_SUMMARY_FILTER_SELECTED_STORE_LIST_ID: {
      return { ...state, selectedStoreListID: payload };
    }
    case SL_SUMMARY_SELECTED_STORE_LIST_DESCRIPTION: {
      return { ...state, selectedStoreListDescription: payload };
    }
    case SL_FILTER_CREATED_BY: {
      return { ...state, selectedStoreListCreatedBy: payload };
    }
    case SL_FILTER_PLACE_ID: {
      return { ...state, selectedStoreListPlaceId: payload };
    }
    case SL_FILTER_LEGACY_STORE_ID: {
      return { ...state, selectedStoreListLegacyStoreId: payload };
    }
    case SL_SUMMARY_SEARCHED_DATA: {
      const error = payload.searchResultStoreList?.length > 0 ? null : 'No records found';
      return { ...state, storeList: payload, storeListSearchError: error }
    }
    case SL_STORE_LIST_DELETE: {
      return { ...state, deleteSuccess: payload, selectedStoreListIds: [] };
    }
    case STORES_IN_STORE_LIST: {
      return { ...state, storesInList: payload }
    };
    case SL_SEARCH_ERROR: {
      return { ...state, storeListSearchError: payload };
    }
    case SL_BANNER_CLOSE: {
      return { ...state, openBanner: payload };
    }
    default:
      return state;
  }
};

export default Reducer;
const initialState = {
    storeList: {
        searchResultStoreList: [],
        resultSize: 1,
    },
    selectedStoreListIds: [],
    selectedStoreListID: '',
    selectedStoreListDescription: '',
    selectedStoreListCreatedBy: '',
    selectedStoreListPlaceId: '',
    selectedStoreListLegacyStoreId: '',
    deleteSuccess: false,
    storesInList: [],
    storeListSearchError: null,
    openBanner: true,
};

export default initialState;


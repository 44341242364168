const initialState = {
    storedetails: [],
    invalidStores: [],
    closedStores: [],
    selectedStoreList: [],
    selectedStoresInSearch: [],
    selectedStoreListDescription: '',
    storeTypeList: {
        storeTypes: [],
    },
    saveStoreListSuccess: null,
    editStoreList: [],
    storeListError: null,
};

export default initialState;
export const ROOT_PATH = '/';

export const WZO_ROOT_PATH = '/wzo';

export const EXCEPTION = `${WZO_ROOT_PATH}/exception`;
export const STORE_LIST_PATH = `${WZO_ROOT_PATH}/storeList`;
export const ITEM_LIST_PATH = `${WZO_ROOT_PATH}/itemList`;

export const ADD_EXCEPTION = `${EXCEPTION}/create`;
export const EDIT_EXCEPTION = `${EXCEPTION}/edit`;
export const SEARCH_EXCEPTION = `${EXCEPTION}/search`;

export const STORE_LIST_CREATE_PATH = `${STORE_LIST_PATH}/create`;
export const SEARCH_STORE_LIST = `${STORE_LIST_PATH}/search`;
export const STORE_LIST_EDIT_PATH = `${STORE_LIST_PATH}/edit`;

export const ITEM_LIST_CREATE_PATH = `${ITEM_LIST_PATH}/create`;
export const SEARCH_ITEM_LIST = `${ITEM_LIST_PATH}/search`;
export const ITEM_LIST_EDIT_PATH = `${ITEM_LIST_PATH}/edit`;

export const STORES_IN_STORE_LIST = `${STORE_LIST_PATH}/storeListId`;
export const ITEMS_IN_ITEM_LIST = `${ITEM_LIST_PATH}/itemListId`;

/* istanbul ignore file */
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './main.scss';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './Common/Components/ErrorBoundary'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import Store from './Common/Redux/Store';
import Loader from './Common/Components/Loader';

const root = ReactDOM.createRoot(document.getElementById('root'));

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <Provider store={Store}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <StrictMode>
              <Routes />
            </StrictMode >
          </Suspense>
        </ErrorBoundary>
      </Provider>
    </MsalProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

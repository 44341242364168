import { memo, useEffect } from 'react';
import { Table } from '@jsluna/table';
import { Card } from '@jsluna/card';
import { OutlinedButton, } from '@jsluna/button';
import { GridWrapper, GridItem } from '@jsluna/grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStoresInListId } from '../Reducer/Action';
import { SEARCH_STORE_LIST } from '../../../Common/Constants/routePaths';
import moment from 'moment';

const StoresInListTable = () => {

  const { state: { storeListId: id } } = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(getStoresInListId(id));
  }, []);

  const storesInList = useSelector((state) => state.ItemStoreInListReducer.storesInList);

  return (
    <div className="ln-u-soft ln-u-padding*1" align='center'>
      <Card>
        {(storesInList?.length > 0) && (
          <GridWrapper>
            <GridItem size={{ sm: '1/2' }}>
              <strong>Store List Id : </strong>{storesInList[0].storeListId}
            </GridItem>
            <GridItem size={{ sm: '1/2' }}>
              <strong>Store List Description : </strong>{storesInList[0].storeListDesc}
            </GridItem>
            <GridItem size={{ sm: '1/2' }}>
              <strong>Created by : </strong>{storesInList[0].userCreated}
            </GridItem>
            <GridItem size={{ sm: '1/2' }}>
              <strong>Creation Date : </strong>{moment((storesInList[0].createdDate)?.slice(0, 10)).format('DD-MM-YYYY')
                + ' ' + (storesInList[0].createdDate)?.slice(11, 19)}
            </GridItem>
          </GridWrapper>
        )}
      </Card>
      <br></br>
      <Card>
        <GridItem
          size='1'
          className="ln-u-border ln-u-soft"
          style={{ maxHeight: '800px', overflowY: 'auto', whiteSpace: 'nowrap' }}
        >
          <Table
            rowKey="item"
            data={storesInList}
            columns={[
              {
                name: "Place Id",
                accessor: (rowData) => ({ value: rowData.placeId }),
              },
              {
                name: "Legacy Store Id",
                accessor: (rowData) => ({ value: rowData.legacyStoreId }),
              },
              {
                name: "Brand",
                accessor: (rowData) => ({ value: rowData.storeBrandName }),
              },
            ]}
          />
        </GridItem>
        <GridWrapper gutterSize='sm'>
          <GridItem size='1/8' offsetRight={{ md: '5/12' }}>
            <OutlinedButton
              className="ln-u-margin-top*2 ln-u-margin-left*4"
              color="dark"
              fullWidth
              onClick={() => navigation(SEARCH_STORE_LIST)}
            >
              Back
            </OutlinedButton>
          </GridItem>
        </GridWrapper>
      </Card>
    </div>
  );
};

export default memo(StoresInListTable); 

import {
    API_REQUEST, ITEMS_IN_ITEM_LIST, STORES_IN_STORE_LIST
} from '../../../../Common/Constants/types';
import {
    ITEM_IN_LIST_ENDPOINT, STORE_IN_LIST_ENDPOINT
} from '../../../../Common/Constants/APIEndpoints';
import { GET } from '../../../../Common/Constants/APIMethods';

export const getStoresInListIdSuccess = (data) => ({
    type: STORES_IN_STORE_LIST,
    payload: data,
});

export const getStoresInListId = (data) => ({
    type: API_REQUEST,
    payload: {
        method: GET,
        url: STORE_IN_LIST_ENDPOINT + data,
        handleSuccess: getStoresInListIdSuccess,
    },
});

export const getItemsInListIdSuccess = (data) => ({
    type: ITEMS_IN_ITEM_LIST,
    payload: data,
});

export const getItemsInListId = (data) => ({
    type: API_REQUEST,
    payload: {
        method: GET,
        url: ITEM_IN_LIST_ENDPOINT + data,
        handleSuccess: getItemsInListIdSuccess,
    },
});

import {
  DELETE_CREATED_EXCEPTION,
  SET_SELECTED_EXCEPTION,
  SET_SEARCH_EXCEPTION,
  SL_EXCEPTION_SUMMARY_RESET,
  STORE_IN_LIST,
  ITEM_IN_LIST,
  SEARCH_EXCEPTION_ERROR,
  EXCEPTION_BANNER_CLOSE,
  EXCEPTION_ROWS_OFFSET,
  SET_ALL_STORES_DATA,
} from "../../../../Common/Constants/types";
import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_CREATED_EXCEPTION: {
      return { ...state, deleteSuccess: payload, selectedExceptionIds: [] };
    }
    case SET_SELECTED_EXCEPTION: {
      return { ...state, selectedExceptionIds: payload };
    }
    case SET_SEARCH_EXCEPTION: {
      const error =
        payload.exceptionSearchResult?.length > 0 ? null : "No records found";
      return { ...state, exception: payload, exceptionSearchError: error };
    }
    case SL_EXCEPTION_SUMMARY_RESET: {
      return {
        ...state,
        exception: {
          exceptionSearchResult: [],
          resultSize: 1,
        },
        selectedExceptionIds: [],
        deleteSuccess: false,
        storesInList: [],
        itemsInList: [],
        exceptionSearchError: null,
        openBanner: true,
        rowsOffset: 50,
      };
    }
    case STORE_IN_LIST: {
      return { ...state, storesInList: payload };
    }
    case ITEM_IN_LIST: {
      return { ...state, itemsInList: payload };
    }
    case SEARCH_EXCEPTION_ERROR: {
      return { ...state, exceptionSearchError: payload };
    }
    case EXCEPTION_BANNER_CLOSE: {
      return { ...state, openBanner: payload };
    }
    case EXCEPTION_ROWS_OFFSET: {
      return { ...state, rowsOffset: payload };
    }
    case SET_ALL_STORES_DATA: {
      return { ...state, allStores: payload };
    }
    default:
      return state;
  }
};

export default reducer;

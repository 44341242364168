/* istanbul ignore file */
import { memo, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Header, HeaderNav, HeaderLogo } from "@jsluna/header";
import { Account } from "@jsluna/icons";
import { LinkButton } from "@jsluna/button";
import { ListGroup, ListGroupDropdown, ListGroupItem } from "@jsluna/list";
import { ROOT_PATH, WZO_ROOT_PATH } from "../../../Common/Constants/routePaths";
import IdleTimer from "../IdleTimer";
import InactiveAlert from "../InactiveAlert";

const NavBar = (props) => {
  const { instance, name, timeOut, awaitTime, handleTokenRefresh } = props;
  const { pathname } = useLocation();
  const [isConfirmationPopUpOpen, setIsConfirmationPopUpOpen] = useState(false);

  const closeConfirmationPopUp = () => {
    setIsConfirmationPopUpOpen(false);
    handleTokenRefresh();
  };

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: timeOut,
      onTimeout: () => {
        setIsConfirmationPopUpOpen(true);
        timer.cleanUp();
      },
    });
    let logoutTimer;
    if (isConfirmationPopUpOpen) {
      logoutTimer = setTimeout(() => {
        window.location.href =
          "https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0";
      }, awaitTime);
    }

    return () => {
      clearTimeout(logoutTimer);
    };
  }, [isConfirmationPopUpOpen]);

  return (
    <>
      <Header>
        {pathname === WZO_ROOT_PATH && (
          <HeaderLogo>
            {/* <Home /> */}
            <Link to={ROOT_PATH} style={{ textDecoration: "none" }}>
              {" "}
              SCMDM{" "}
            </Link>
          </HeaderLogo>
        )}
        {!(pathname === ROOT_PATH) && !(pathname === WZO_ROOT_PATH) && (
          <HeaderLogo>
            {/* <Home /> */}
            <Link to={WZO_ROOT_PATH} style={{ textDecoration: "none" }}>
              SCMDM - Non-Replenishment lines
            </Link>
          </HeaderLogo>
        )}
        <HeaderNav align="right" openDropdownOnHover drawer label="Main">
          <ListGroupDropdown id="user">
            <Account size="default" />
            <span className="ln-u-margin-left">Hi, {name}</span>
            <ListGroup style={{ width: "100%" }}>
              <ListGroupItem>
                <LinkButton
                  onClick={() => {
                    instance.logoutRedirect();
                  }}
                >
                  SignOut
                </LinkButton>
              </ListGroupItem>
            </ListGroup>
          </ListGroupDropdown>
        </HeaderNav>
      </Header>
      <InactiveAlert
        open={isConfirmationPopUpOpen}
        awaitTime={awaitTime}
        closeSessionPopup={closeConfirmationPopUp}
      />
    </>
  );
};

export default memo(NavBar);

import {
  ITEMS_IN_ITEM_LIST,
  STORES_IN_STORE_LIST
} from '../../../Common/Constants/types';
import initialState from './initialState';

const Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STORES_IN_STORE_LIST: {
      return { ...state, storesInList: payload }
    };
    case ITEMS_IN_ITEM_LIST: {
      return { ...state, itemsInList: payload }
    };
    default:
      return state;
  }
};

export default Reducer;
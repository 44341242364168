/* Common */
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const GENERIC_API_ENDPOINT = process.env.REACT_APP_GENERIC_API_ENDPOINT;

// Search item/Store
export const SEARCH_ITEM_ENDPOINT = `${API_ENDPOINT}item`;
export const SEARCH_ITEM_LIST_ENDPOINT = `${API_ENDPOINT}itemList`;
export const SEARCH_STORE_ENDPOINT = `${API_ENDPOINT}store`;
export const SEARCH_STORE_LIST_ENDPOINT = `${API_ENDPOINT}storeList`;

// save
export const SAVE_EXCEPTION_ENDPOINT = `${API_ENDPOINT}exception/save`;
export const CHECK_UNIQUE_SL_ID = `${API_ENDPOINT}storeList/check/`;
export const SAVE_STORE_LIST_ENDPOINT = `${API_ENDPOINT}storeList/save`;
export const CHECK_UNIQUE_IL_ID = `${API_ENDPOINT}itemList/check/`;
export const SAVE_ITEM_LIST_ENDPOINT = `${API_ENDPOINT}itemList/save`;

// search
export const EXCEPTION_ENDPOINT = `${API_ENDPOINT}exception/search`;
export const ITEMLIST_ENDPOINT = `${API_ENDPOINT}itemList/search`;
export const STORELIST_ENDPOINT = `${API_ENDPOINT}storeList/search`;

// get details to edit
export const EXCEPTION_ID_GET_DETAILS_ENDPOINT = `${API_ENDPOINT}exception/getExceptionDetails/`;
export const STORELIST_ID_GET_DETAILS_ENDPOINT = `${API_ENDPOINT}storeList/getStoreList/`;
export const ITEMLIST_ID_GET_DETAILS_ENDPOINT = `${API_ENDPOINT}itemList/getItemList/`;

// edit endpoints
export const EXCEPTION_EDIT_ENDPOINT = `${API_ENDPOINT}exception/edit`;
export const STORELIST_EDIT_ENDPOINT = `${API_ENDPOINT}storeList/edit`;
export const ITEMLIST_EDIT_ENDPOINT = `${API_ENDPOINT}itemList/edit`;

// delete
export const DELETE_EXCEPTION_ENDPOINT = `${API_ENDPOINT}exception/delete`;
export const DELETE_ITEMLIST_ENDPOINT = `${API_ENDPOINT}itemList/delete`;
export const DELETE_STORELIST_ENDPOINT = `${API_ENDPOINT}storeList/delete`;

// Get Store/Item Ids in a list id
export const STORE_IN_LIST_ENDPOINT = `${API_ENDPOINT}storeList/getStoreList/`;
export const ITEM_IN_LIST_ENDPOINT = `${API_ENDPOINT}itemList/getItemList/`;

//fetch data
export const FETCH_EXCEPTION_REASON = `${API_ENDPOINT}exception/getExceptionReasons`;
export const FETCH_STORE_TYPES = `${API_ENDPOINT}store/getStoreType`;

//multiple id search
export const STORE_MULTIPLE_ID_SEARCH = `${API_ENDPOINT}store/multipleIdSearch`;
export const ITEM_MULTIPLE_ID_SEARCH = `${API_ENDPOINT}item/multipleIdSearch`;

// get All Stores
export const GET_ALL_STORES = `${GENERIC_API_ENDPOINT}generic/getAllStores`
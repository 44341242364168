/* Common reducer */
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const API_REQUEST = 'API_REQUEST';
export const NOT_USED = 'NOT_USED';

// EXCEPTION
export const SELECT_ITEM = 'SELECT_ITEM';
export const UNSELECT_ITEM = 'UNSELECT_ITEM';
export const SELECT_STORE = 'SELECT_STORE';
export const UNSELECT_STORE = 'UNSELECT_STORE';
export const SELECT_STORE_BRAND = 'SELECT_STORE_BRAND';
export const SELECT_ITEM_BRAND = 'SELECT_ITEM_BRAND';

export const SET_EXCEPTION_DESCRIPTION = 'SET_EXCEPTION_DESCRIPTION';
export const SET_EXCEPTION_REASON = 'SET_EXCEPTION_REASON';
export const SET_EXCEPTION_START_DATE = 'SET_EXCEPTION_START_DATE';
export const SET_EXCEPTION_END_DATE = 'SET_EXCEPTION_END_DATE';
export const RESET_EXCEPTION_DETAILS = 'RESET_EXCEPTION_DETAILS';
export const SAVE_EXCEPTION = 'SAVE_EXCEPTION';
export const SET_EXCEPTION_EDIT_DATA = 'SET_EXCEPTION_EDIT_DATA';
export const EXCEPTION_RESET = 'EXCEPTION_RESET';
export const EXCEPTION_ERROR = 'EXCEPTION_ERROR';
export const EXCEPTION_ITEM_FLAG = 'EXCEPTION_ITEM_FLAG';
export const EXCEPTION_STORE_FLAG = 'EXCEPTION_STORE_FLAG';
export const EXCEPTION_REASONS = 'EXCEPTION_REASONS';

// exception search
export const DELETE_CREATED_EXCEPTION = 'DELETE_CREATED_EXCEPTION';
export const SET_SELECTED_EXCEPTION = 'SET_SELECTED_EXCEPTION';
export const SET_SEARCH_EXCEPTION = 'SET_SEARCH_EXCEPTION';
export const SL_EXCEPTION_SUMMARY_RESET = 'SL_EXCEPTION_SUMMARY_RESET';
export const SEARCH_EXCEPTION_ERROR = 'SEARCH_EXCEPTION_ERROR';
export const FILTER_EXCEPTION_ID = 'FILTER_EXCEPTION_ID';
export const FILTER_EXCEPTION_DESCRIPTION = 'FILTER_EXCEPTION_DESCRIPTION';
export const FILTER_EXCEPTION_CREATED_BY = 'FILTER_EXCEPTION_CREATED_BY';

// STORE LIST
export const STORE_TYPE_LIST = 'STORE_TYPE_LIST';
export const SET_STORE_LIST_DESCRIPTION = 'SET_STORE_LIST_DESCRIPTION';
export const SELECTED_STORE_IN_SEARCH = 'SELECTED_STORE_IN_SEARCH';
export const SELECT_STORE_IN_STORE_LIST = 'SELECT_STORE_IN_STORE_LIST';
export const UNSELECT_STORE_IN_STORE_LIST = 'UNSELECT_STORE_IN_STORE_LIST';
export const MULTIPLE_STORE_IDS_SEARCH = 'MULTIPLE_STORE_IDS_SEARCH';

export const SAVE_STORE_LIST = 'SAVE_STORE_LIST';
export const SAVE_STORE_TO_PREVIEW = 'SAVE_STORE_TO_PREVIEW';
export const RESET_STORE_LIST_DETAILS = 'RESET_STORE_LIST_DETAILS';
export const CLEAR_STORE_LIST_PREVIEW = 'CLEAR_STORE_LIST_PREVIEW';
export const DELETE_STORE_FROM_PREVIEW = 'DELETE_STORE_FROM_PREVIEW';
export const SET_SL_EDIT_DATA = 'SET_SL_EDIT_DATA';
export const SL_RESET = 'SL_RESET';
export const SL_ERROR = 'SL_ERROR';

// Search StoreList
export const SL_SUMMARY_SELECTED_STORE_LIST_ID = 'SL_SUMMARY_SELECTED_STORE_LIST_ID';
export const SL_STORE_LIST_DELETE = 'SL_STORE_LIST_DELETE';
export const SL_SUMMARY_RESET = 'SL_SUMMARY_RESET';
export const SL_SUMMARY_SEARCHED_DATA = 'SL_SUMMARY_SEARCHED_DATA';
export const SL_SUMMARY_FILTER_SELECTED_STORE_LIST_ID = 'SL_SUMMARY_FILTER_SELECTED_STORE_LIST_ID';
export const SL_SUMMARY_SELECTED_STORE_LIST_DESCRIPTION = 'SL_SUMMARY_SELECTED_STORE_LIST_DESCRIPTION';
export const SL_FILTER_CREATED_BY = 'SL_FILTER_CREATED_BY';
export const SL_FILTER_PLACE_ID = 'SL_FILTER_PLACE_ID';
export const SL_FILTER_LEGACY_STORE_ID = 'SL_FILTER_LEGACY_STORE_ID';
export const SET_SUMMARY_STORE_LIST = 'SET_SUMMARY_STORELIST';
export const STORES_IN_STORE_LIST = 'STORES_IN_STORE_LIST';
export const SL_SEARCH_ERROR = 'SL_SEARCH_ERROR';

export const SORT_SL_STORE_LIST_ID = 'SORT_SL_STORE_LIST_ID';

// ITEM LIST
export const SET_ITEM_LIST_DESCRIPTION = 'SET_ITEM_LIST_DESCRIPTION';
export const SELECTED_ITEM_IN_SEARCH = 'SELECTED_ITEM_IN_SEARCH';
export const SELECT_ITEM_IN_ITEM_LIST = 'SELECT_ITEM_IN_ITEM_LIST';
export const UNSELECT_ITEM_IN_ITEM_LIST = 'UNSELECT_ITEM_IN_ITEM_LIST';
export const MULTIPLE_ITEMS_IDS_SEARCH = 'MULTIPLE_ITEMS_IDS_SEARCH';

export const SAVE_ITEM_LIST = 'SAVE_ITEM_LIST';
export const SAVE_ITEM_TO_PREVIEW = 'SAVE_ITEM_TO_PREVIEW';
export const RESET_ITEM_LIST_DETAILS = 'RESET_ITEM_LIST_DETAILS';
export const CLEAR_ITEM_LIST_PREVIEW = 'CLEAR_ITEM_LIST_PREVIEW';
export const DELETE_ITEM_FROM_PREVIEW = 'DELETE_ITEM_FROM_PREVIEW';
export const SET_IL_EDIT_DATA = 'SET_IL_EDIT_DATA';
export const IL_RESET = 'IL_RESET';
export const IL_ERROR = 'IL_ERROR';

// Search ItemList
export const IL_SUMMARY_SELECTED_ITEM_LIST_ID = 'IL_SUMMARY_SELECTED_ITEM_LIST_ID';
export const IL_ITEM_LIST_DELETE = 'IL_ITEM_LIST_DELETE';
export const IL_SUMMARY_RESET = 'IL_SUMMARY_RESET';
export const IL_SUMMARY_SEARCHED_DATA = 'IL_SUMMARY_SEARCHED_DATA';
export const IL_SUMMARY_FILTER_SELECTED_ITEM_LIST_ID = 'IL_SUMMARY_FILTER_SELECTED_ITEM_LIST_ID';
export const IL_SUMMARY_SELECTED_ITEM_LIST_DESCRIPTION = 'IL_SUMMARY_SELECTED_ITEM_LIST_DESCRIPTION';
export const IL_FILTER_CREATED_BY = 'IL_FILTER_CREATED_BY';
export const IL_FILTER_SAIN_ID = 'IL_FILTER_SAIN_ID';
export const IL_FILTER_LEGACY_ITEM_ID = 'IL_FILTER_LEGACY_ITEM_ID';
export const SET_SUMMARY_ITEM_LIST = 'SET_SUMMARY_ITEM_LIST';
export const ITEMS_IN_ITEM_LIST = 'ITEMS_IN_ITEM_LIST';
export const IL_SEARCH_ERROR = 'IL_SEARCH_ERROR';

export const SORT_IL_ITEM_LIST_ID = 'SORT_IL_ITEM_LIST_ID';

// Get Items/stores from list
export const ITEM_IN_LIST = 'ITEM_IN_LIST';
export const STORE_IN_LIST = 'STORE_IN_LIST';

//Close Banner
export const EXCEPTION_BANNER_CLOSE = 'EXCEPTION_BANNER_CLOSE';
export const SL_BANNER_CLOSE = 'SL_BANNER_CLOSE';
export const IL_BANNER_CLOSE = 'IL_BANNER_CLOSE';

//Rows Offset
export const EXCEPTION_ROWS_OFFSET = "EXCEPTION_ROWS_OFFSET";

// Get All Stores
export const SET_ALL_STORES_DATA = "SET_ALL_STORES_DATA";